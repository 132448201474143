@tailwind base;
@tailwind components;
@tailwind utilities;

@media (prefers-reduced-motion: no-preference) {
    .logo {
        animation: logo-spin infinite 20s linear;
    }
}

@keyframes logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}